import { graphql, useStaticQuery } from "gatsby";
import React, { memo } from "react";
import { Helmet } from "react-helmet";

import { SEOQuery } from "../../../__generated__/queries";

interface Props {
  description?: string | null;
  image?: string | null;
  title?: string | null;
  type?: "website" | "article";
  url?: string | null;
}

const SEO: React.FC<Props> = ({
  description,
  image,
  title,
  type = "website",
  url,
}) => {
  const data = useStaticQuery<SEOQuery>(graphql`
    query SEOQuery {
      site {
        siteMetadata {
          description
          siteUrl
          title
        }
      }
      image: file(name: { eq: "especes-en-danger" }, ext: { eq: ".jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            src
          }
        }
      }
    }
  `);

  const fullTitle = `${title ? `${title} - ` : ""}${
    data.site?.siteMetadata?.title
  }`;
  const fullUrl = `${data.site?.siteMetadata?.siteUrl}${url ? `/${url}` : ""}`;
  const fullImage = `${data.site?.siteMetadata?.siteUrl}${
    url ? `${image}` : data.image?.childImageSharp?.fluid?.src
  }`;
  const fullDescription =
    description || data.site?.siteMetadata?.description || "";

  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta property="og:title" content={fullTitle} />
      <meta name="twitter:title" content={fullTitle} />

      <meta name="description" content={fullDescription} />
      <meta property="og:description" content={fullDescription} />
      <meta name="twitter:description" content={fullDescription} />

      <meta name="image" content={fullImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={fullImage} />
      <meta property="og:image" content={fullImage} />
      <meta property="og:image" content={fullImage} />

      <meta property="og:url" content={fullUrl} />
      <meta property="og:type" content={type} />
    </Helmet>
  );
};

export const query = graphql`
  fragment SEO on MarkdownRemarkFrontmatter {
    seoData: seo {
      title
      description
      picture {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            src
          }
        }
      }
    }
  }
`;

export default memo(SEO);
